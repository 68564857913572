import { Injectable } from '@angular/core';
//import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private afAuth: AngularFireAuth) { }
  signUp(email: string, password: string) {
    this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(() => {
        // Sign up successful
      })
      .catch((error) => {
       return error;
      });
  }
  login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
}

logout() {
    return this.afAuth.signOut();
}

getUser() {
    return this.afAuth.authState;
}

}